.department-datatable {
    background: none;

    .p-datatable .p-datatable-tbody>tr {
        background: none;

        td {
            padding: 3px 7px 0 0;
            color: white;
            border: none
        }
    }

    .p-datatable .p-datatable-thead>tr {
        background: none;

        th {
            background: none;
            padding: 0 0 4px 0;
            color: white;
            border-width: 0 0 1px 0
        }

    }
}